// Import global vars
@import "master";
@import "fonts";
@import "fontello";

html,
body {
  height: 100%;
  padding: 0;
}
body {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-family: "Myriad Pro", sans-serif;
  color: #414042;
}
.main-content {
  flex: 1 0 auto;
}
.main-footer {
  flex-shrink: 0;
}

* {
  scroll-margin-top: 100px;
}
.partner-well-wrap {
  scroll-margin-top: 150px !important;
}

img {
  max-width: 100%;
}

h4 {
  font-size: 35px;
  font-weight: bold;
}
// p {
//   font-size: 25px;
// }

.bg-grad {
  background: rgb(252, 252, 252);
  background: linear-gradient(
    180deg,
    rgba(252, 252, 252, 1) 0%,
    rgba(229, 229, 229, 1) 100%
  );
}

// .ty-list {
//   font-size: 25px;
// }

.w-33 {
  width: 33% !important;
}

.carousel-inner {
  padding-bottom: 80px;
}

.dropdown > .dropdown-menu {
  border: none;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}
.dropdown.dropdown-on-hover:hover > .dropdown-menu {
  display: block;
}
.dropdown.dropdown-on-hover > a {
  padding-bottom: 20px;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: #1181a6;
}

.ty-button {
  display: inline-block;
  text-decoration: none !important;
  outline: none !important;
  background: #fff;
  padding: 14px 40px;
  border-radius: 10px;
  font-size: 24px;
  color: #1181a6;
  font-weight: bold;
}

.main-logo {
  max-width: 185px;
  width: 100%;
}
.footer-logo {
  max-width: 300px;
  width: 100%;
}

.main-header {
  padding: 10px 0;
  box-shadow: 0 7px 10px rgba(0, 0, 0, 0.15);
  background: #fff;

  &--fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
  }

  .navbar-brand {
    margin-right: 125px;
  }

  .navbar-nav {
    .nav-item {
      padding-bottom: 6px;

      &:not(:last-child) {
        margin-right: 10px;
      }
      .nav-link {
        padding: 12px 26px;
        border-radius: 50px;
        font-size: 18px;
        text-transform: uppercase;
        &.active {
          background: #1181a6;
          color: #fff;
        }
      }
    }
  }
}
.main-footer {
  background: url("../assets/footer_bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right bottom;
  // min-height: 565px;
  padding-top: 40px;
  padding-bottom: 80px;

  &__col-title {
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 36px;
  }

  &__menu {
    list-style: none;
    padding: 0;
    margin: 0;
    &-item {
      font-size: 18px;
      a {
        color: #414042;
        text-decoration: none !important;
      }
      &:not(:last-child) {
        margin-bottom: 22px;
      }
    }
  }

  &__info {
    display: flex;
    &-icon-wrap {
      margin-right: 20px;
      i {
        font-size: 30px;
      }
    }
    &-content {
      font-size: 20px;
    }

    &-separator {
      height: 4px !important;
      opacity: 1 !important;
      background-color: #0f85a4;
    }
  }
}

.section-hero {
  padding: 0 !important;
  /* The image used */
  background-image: url("../assets/hero.jpg");

  /* Set a specific height */
  // min-height: 720px;

  /* Create the parallax scrolling effect */
  // background-attachment: fixed;
  background-position: 0 -50px;
  background-repeat: no-repeat;
  background-size: cover;

  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.3);
    pointer-events: none;
  }

  &__content {
    position: relative;
    z-index: 1;

    text-align: center;
    color: #fff;
    padding: 225px 0;
  }
  &__title {
    font-size: 70px;
    margin-bottom: 40px;
  }
  &__text {
    font-size: 28px;
    margin-bottom: 12px;
  }
  &__btn {
    margin-top: 20px;
  }
}

.section {
  padding: 60px 0;
}
.section__title {
  font-size: 36px;
  position: relative;
  padding-bottom: 25px;
  text-align: center;
  // text-transform: uppercase;
  font-weight: bold;
  // display: inline-block;
  margin-bottom: 50px;
  &::after {
    content: "";
    position: absolute;
    width: 50%;
    max-width: 150px;
    bottom: 0;
    height: 4px;
    left: 50%;
    transform: translateX(-50%);
    background: #1181a6;
  }
}

.icon-card {
  text-align: center;
  background: rgb(252, 252, 252);
  background: linear-gradient(
    180deg,
    rgba(252, 252, 252, 1) 0%,
    rgba(229, 229, 229, 1) 100%
  );
  padding: 125px 15px;
  margin-bottom: 20px;

  &__image-wrap {
    margin-bottom: 50px;
  }
  &__text {
    font-size: 24px;
  }

  transition: 400ms;
  &:hover {
    // transform: translateY(-15px);
    background: linear-gradient(
      0,
      rgba(229, 229, 229, 1) 0%,
      rgba(229, 229, 229, 1) 100%
    );
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
}
.css-selector {
  background: linear-gradient(180deg, #fcfcfc, #e5e5e5);
  background-size: 400% 400%;

  -webkit-animation: iconCardBg 4s ease infinite;
  -moz-animation: iconCardBg 4s ease infinite;
  animation: iconCardBg 4s ease infinite;
}

@-webkit-keyframes iconCardBg {
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 51% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}
@-moz-keyframes iconCardBg {
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 51% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}
@keyframes iconCardBg {
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 51% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}

.contact-info {
  &__title {
    font-size: 34px;
    font-weight: bold;
    color: #002245;
    margin-bottom: 50px;
  }
  &__subtitle {
    font-size: 28px;
    margin-bottom: 30px;
  }
  &__item {
    font-size: 28px;
    &:not(:last-child) {
      margin-bottom: 14px;
    }

    span {
      margin-right: 20px;
    }
    i {
      font-size: 24px;
    }
  }
}

.card-well {
  position: relative;
  padding: 50px 15px;
  margin-bottom: 50px;
  &::after {
    content: "";
    height: 140px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    pointer-events: none;
    background: rgb(245, 245, 245);
    background: linear-gradient(
      0deg,
      rgba(245, 245, 245, 1) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }

  &__title {
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    margin-top: 30px;
  }
}

.ty-card {
  position: relative;
  margin-bottom: 20px;

  &__image-wrap {
  }
  &__image {
  }
  &__content {
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(#fff, 0.3);
    width: 100%;
    padding: 20px 30px;
    padding-bottom: 40px;
    transition: 400ms;
  }
  &__title {
    color: #fff;
    font-weight: bold;
    font-size: 40px;
  }

  transition: 400ms;
  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    .ty-card__content {
      background: rgba(#1181a6, 0.5);
    }
  }
}

.slogan-title {
  font-size: 48px;
  font-weight: bold;
  color: #1181a6;
}

.leader-card {
  margin-bottom: 20px;

  &__image-wrap {
    margin-bottom: 20px;
  }
  &__image {
  }
  &__content {
    text-align: center;
  }
  &__title {
    font-size: 40px;
    font-weight: bold;
  }
  &__subtitle {
    font-size: 32px;
  }
}

.partner-well-wrap {
  &:not(:last-child) {
    margin-bottom: 120px;
  }
}
.partner-well {
  position: relative;
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.15);
  overflow: hidden;

  &:hover {
    .partner-well__front {
      opacity: 0;
      pointer-events: none;
    }
    .partner-well__back {
      transform: translateY(0);
    }
  }

  &__front {
    // position: relative;
    z-index: 2;
    // opacity: 0;
    transition: 600ms;

    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  &__back {
    position: relative;
    z-index: 1;
    padding: 80px;
    transform: translateY(25%);
    transition: 400ms;
  }

  &__image-wrap {
    position: relative;
    height: 100%;
    width: 100%;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #fff;
      opacity: 0.6;
      pointer-events: none;
    }
  }

  &__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__overlay-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 40px;
    font-weight: bold;
    text-transform: uppercase;
    pointer-events: none;
    text-align: center;
  }

  &__title {
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 50px;
    text-transform: uppercase;
  }
}

.partner-rows {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  margin-left: -20px;
  margin-right: -20px;
  &__item {
    width: 20%;
    padding: 0 20px;
    margin-bottom: 30px;
    transition: 400ms;
    a {
      color: inherit;
      text-decoration: none;
    }

    &:hover {
      transform: translateY(-20px);
    }
    img {
      width: 100%;
      padding: 20px 30px;
    }
    &-title {
      font-size: 20px;
      text-align: center;
    }
  }
}
