// @font-face {
//   font-family: 'MyWebFont';
//   src: url('webfont.eot'); /* IE9 Compat Modes */
//   src: url('webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//        url('webfont.woff') format('woff'), /* Modern Browsers */
//        url('webfont.ttf')  format('truetype'), /* Safari, Android, iOS */
//        url('webfont.svg#svgFontName') format('svg'); /* Legacy iOS */
// }

// @font-face {
//   font-family: "Open Sans";
//   font-style: normal;
//   font-weight: 400;
//   src: local("Open Sans"), local("OpenSans-Regular"),
//     url("../fonts/OpenSans/OpenSans-Regular.woff2") format("woff2"),
//     url("../fonts/OpenSans/OpenSans-Regular.ttf") format("truetype");
// }

// @font-face {
//   font-family: "Open Sans";
//   font-style: normal;
//   font-weight: 700;
//   src: local("Open Sans Bold"), local("OpenSans-Bold"),
//     url("../fonts/OpenSans/OpenSans-Bold.woff2") format("woff2"),
//     url("../fonts/OpenSans/OpenSans-Bold.ttf") format("truetype");
// }

@font-face {
  font-family: "Myriad Pro";
  font-style: normal;
  font-weight: 400;
  src: local("Myriad Pro Regular"),
    url("../fonts/myriad-pro/MYRIADPRO-REGULAR.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro";
  font-style: normal;
  font-weight: 700;
  src: local("Myriad Pro Bold"),
    url("../fonts/myriad-pro/MYRIADPRO-BOLD.woff") format("woff");
}
